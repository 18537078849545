import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ADMINTOOL_GATEWAY_URL
});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        config.headers = new AxiosHeaders();
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        const token = sessionStorage.getItem('_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error: Error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
