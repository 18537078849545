import { UseMutationResult, useMutation } from '@tanstack/react-query';

import axiosInstance from '../../../axios-interceptor';
import { BookWebinarFormValues } from '../../../types';

interface CreateWebinarResponse {
    seminarId: string,
    message: string,
    status: string
}

interface WebinarError {
    response?: {
        status: number,
        data?: {
            message: string
        }
    }
}

export const useBookWebinars = () => {
    const createWebinarMutation: UseMutationResult<CreateWebinarResponse, WebinarError, BookWebinarFormValues, unknown> = useMutation({
        mutationFn: async (values: BookWebinarFormValues) => {
            const response = await axiosInstance.post('/book-webinar', values);
            return response.data as CreateWebinarResponse;
        }
    });

    return { createWebinarMutation };
};
