import { Fragment, useState } from 'react';


import { Button } from '@80db/core-ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormikProps, FormikValues, withFormik } from 'formik';
import * as Yup from 'yup';

import { Country, UserProfileFormProps } from './types';
import axiosInstance from '../../axios-interceptor';
import { AttributeType, DropDownItem, ProfileAttribute, USER_PROFILE_ATTRIBUTES } from '../../types';
import { FormContainer, FormikCheckboxField, FormikRadioGroup, FormikSelectInputField, FormikTextAreaField, FormikTextInputField, UserAvatar } from '../Shared';

const UserProfileInnerForm = (props: UserProfileFormProps & FormikProps<FormikValues>) => {
    const { profileAttribs, resetForm, setMessage } = props;

    const handleChange = (e) => {
        setMessage('');
        props.handleChange(e);
    };

    const handleBlur = (e) => {
        setMessage('');
        props.handleBlur(e);
    };

    const formFields = profileAttribs.map((profileAttrib) => {
        const { attributeType, attribute } = profileAttrib;
        const { name, attributeId, items = [], defaultChecked, readOnly } = attribute;
        switch (attributeType) {
            case AttributeType.String: {
                return <FormikTextInputField key={attributeId} name={name} label={name} readOnly={readOnly} onChange={handleChange} onBlur={handleBlur} />;
            }
            case AttributeType.Integer:
            case AttributeType.Double: {
                return (
                    <FormikTextInputField
                        key={attributeId}
                        type="number"
                        name={name}
                        label={name}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }
            case AttributeType.DropDownList: {
                return (
                    <FormikSelectInputField
                        key={attributeId}
                        name={name}
                        label={name}
                        options={items}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }
            case AttributeType.Boolean:
            case AttributeType.LinkBoolean: {
                return (
                    <FormikCheckboxField
                        key={attributeId}
                        name={name}
                        label={name}
                        defaultChecked={defaultChecked}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }
            case AttributeType.CountryDropDownList: {
                return (
                    <FormikSelectInputField
                        key={attributeId}
                        name={name}
                        label={name}
                        options={items}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }
            case AttributeType.RadioButtons: {
                return (
                    <FormikRadioGroup
                        key={attributeId}
                        name={name}
                        label={name}
                        options={items}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }
            case AttributeType.HTMLField: {
                return <FormikTextAreaField key={attributeId} name={name} label={name} readOnly={readOnly} onChange={handleChange} onBlur={handleBlur} />;
            }
            case AttributeType.Date: {
                return (
                    <FormikTextInputField
                        key={attributeId}
                        type="date"
                        name={name}
                        label={name}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            }

            default:
                return <Fragment key={attribute.attributeId}></Fragment>;
        }
    });
    return (
        <FormContainer>
            <>
                {formFields}
                <div className="flex gap-4 flex-wrap">
                    <Button
                        variant="primary-outline"
                        onClick={(event) => {
                            resetForm();
                            event.preventDefault();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" variant="primary" classNames="bg-blue-500">
                        Save changes
                    </Button>
                </div>
            </>
        </FormContainer>
    );
};

const UserProfileForm = withFormik<UserProfileFormProps, FormikValues>({
    mapPropsToValues: (props) => {
        const formValues: FormikValues = {};
        props.profileAttribs.forEach(({ attribute }) => {
            const { name: key, value } = attribute;
            formValues[key] = value;
        });

        console.log(formValues);
        return formValues;
    },

    validationSchema: (props: UserProfileFormProps) => {
        const formValidation: { [key: string]: Yup.StringSchema } = {};
        props.profileAttribs.forEach(({ attribute }) => {
            const { name: key, isRequired, regex } = attribute;
            let fieldValidations = Yup.string();
            if (isRequired) {
                fieldValidations = fieldValidations.required(`${key} is required`);
            }
            if (regex) {
                fieldValidations = fieldValidations.matches(new RegExp(regex), `${key} is Invalid`);
            }

            formValidation[key] = fieldValidations;
        });

        return Yup.object({
            ...formValidation
        });
    },

    handleSubmit: async (values, args) => {
        console.log('submit');
        console.log(values);
        const request: FormikValues = {};
        for (const [key, value] of Object.entries(values)) {
            if (key) {
                request[key] = value.toString();
            }
        }
        args.props.submit(request);
    },

    validateOnBlur: true,

    validateOnChange: true,

    enableReinitialize: true,

    displayName: 'UserProfileForm'
})(UserProfileInnerForm);

export const EditProfile = () => {
    const [message, setMessage] = useState<string>();
    const queryClient = useQueryClient();
    const { data: profileAttribs } = useQuery<ProfileAttribute[]>({
        queryKey: [USER_PROFILE_ATTRIBUTES],
        queryFn: async () => {
            setMessage('');
            const response = await axiosInstance.get<ProfileAttribute[]>(`/profile/edit`);
            const profileAttribs = response.data;

            console.log(profileAttribs);
            const countryAttrib = profileAttribs.find(({ attribute }) => attribute.attributeId === 1342);
            if (countryAttrib) {
                const countries = await axiosInstance.get(`/countries`);
                const items: DropDownItem[] = (countries.data || []).map((country: Country) => ({
                    text: country.name,
                    value: country.code
                }));

                countryAttrib.attribute.items = items;
            }

            return profileAttribs;
        }
    });

    const saveUserProfile = async (values: FormikValues) => {
        await axiosInstance.post(`/profile/edit`, values).catch((err) => {
            const errorMsg = `User profile updated failed with error - ${Object.entries(err.response.data)}`;
            throw new Error(errorMsg);
        });
    };

    const { mutateAsync } = useMutation({
        mutationFn: saveUserProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USER_PROFILE_ATTRIBUTES] });
            setMessage('User profile updated successfully');
        },

        onError: (err: Error) => {
            setMessage(err.message || 'User profile updated failed');
        }
    });

    return (
        <div className="">
            <div className="md:max-w-2xl lg:max-w-[50%] p-4 md:p-8">
                <h2 className="pb-4 md:pb-10">My profile</h2>

                <UserAvatar classNames="w-20 h-20 md:w-30 md:h-30 rounded-full border-dashed border-2 border-black/50 text-3xl md:text-5xl flex items-center justify-center uppercase font-light mb-4 md:mb-10" />
                {profileAttribs && (
                    <UserProfileForm
                        profileAttribs={profileAttribs}
                        submit={(data: FormikValues) => mutateAsync(data)}
                        setMessage={setMessage}
                    ></UserProfileForm>
                )}
            </div>
            <div className="px-4 max-w-[540px]">
                {message && (
                    <div data-testid="messageToDisplay" className="flex mx-8 text-blue-500 mb-6">
                        {message}
                    </div>
                )}
            </div>
        </div>
    );
};
