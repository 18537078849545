import { ReactNode, createContext, useMemo } from 'react';

import { flushSync } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios-interceptor';
import { LoginFormValues } from '../../components';
import { useLocalStorage } from '../../hooks';
import { ChangePasswordValues, ResetPasswordValues } from '../types';

export interface AuthContextType {
    userAccessToken: string,
    login: (values: LoginFormValues) => Promise<unknown>,
    logout: () => void,
    changePassword: (values: ChangePasswordValues) => Promise<unknown>,
    forgetPassword: (email: string) => Promise<unknown>,
    resetPassword: (values: ResetPasswordValues) => Promise<unknown>
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [userAccessToken, setUserAccessToken] = useLocalStorage('_token', null);
    const navigate = useNavigate();
    const location = useLocation();

    const login = async (values: LoginFormValues) => {
        return await axiosInstance.post('/auth/login', values).then(async (res: AxiosResponse) => {
            const { accessToken } = res.data;
            sessionStorage.setItem('_token', accessToken);
            const userRes = await axiosInstance.get('/auth/user-info');
            sessionStorage.setItem('_userId', userRes.data.userId);

            flushSync(() => {
                setUserAccessToken(accessToken);
            });
            const { pathname, search, state } = location || {};

            setTimeout(() => {
                navigate(`${pathname ?? '/dashboard'}${search ?? ''}`, {
                    replace: true,
                    state
                });
            }, 100);
        });
    };

    const logout = async () => {
        try {
            await axiosInstance.post('/auth/logout');
        } finally {
            setUserAccessToken(null);
            sessionStorage.clear();
            navigate('/login', { replace: true });
        }
    };

    const changePassword = async (values: ChangePasswordValues) => {
        return await axiosInstance
            .post('/auth/change-password', values)
            .then(async (res: AxiosResponse) => {
                const { accessToken } = res.data;
                sessionStorage.setItem('_token', accessToken);

                flushSync(() => {
                    setUserAccessToken(accessToken);
                });
            })
            .catch((err) => {
                const errorMsg = `Change password failed with error - ${Object.entries(err.response.data)}`;
                throw new Error(errorMsg);
            });
    };

    const forgetPassword = async (email: string) => await axiosInstance.post('/auth/reset-password/initialize', { email });

    const resetPassword = async (values: ResetPasswordValues) => await axiosInstance.post('/auth/reset-password', values);

    const value = useMemo(
        () => ({
            userAccessToken,
            login,
            logout,
            changePassword,
            forgetPassword,
            resetPassword
        }),
        [userAccessToken]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
