import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Loading } from '@80db/core-ui';
import { useQuery } from '@tanstack/react-query';

import axiosInstance from '../../axios-interceptor';
import { useLocalStorage } from '../../hooks';
import { Seminar, UPCOMING_SEMINARS_BY_ACCESS, User } from '../../types';
import { PastOrStartedSeminars } from '../Widgets/PastOrStartedSeminars';
import { UpcomingSeminars } from '../Widgets/Upcoming';
import { NoUpcomingEvent, UpNextEvent } from '../Widgets/UpNext';

export const SeminarsOverview = () => {
    const navigate = useNavigate();
    const [storedValue] = useLocalStorage('_user', null);
    const access = (storedValue as User)?.access || 0;

    const {
        data: allSeminars = [],
        isLoading = true,
        isSuccess,
        isError
    } = useQuery<Seminar[]>({
        queryKey: [UPCOMING_SEMINARS_BY_ACCESS],
        queryFn: async () => {
            const res = await axiosInstance.get(`/seminars/${access}/upcoming-by-access`, {
                params: {
                    PageIndex: 0,
                    PageSize: 50
                }
            });

            const allSeminars = res.data.list;
            return allSeminars;
        }
    });

    useEffect(() => {
        if (isError) {
            navigate('/');
        }
    }, [isError]);

    if (isLoading) {
        return (
            <div className="flex h-full">
                <div className="m-auto">
                    <Loading />
                </div>
            </div>
        );
    }

    const upcomingSeminarDetails = (allSeminars || []).filter(({ startDate }: { startDate: string }) => new Date(startDate) >= new Date()) as Seminar[];

    const sortedUpcomingSeminars = upcomingSeminarDetails.sort((a: Seminar, b: Seminar) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const previousSeminarDetails = (allSeminars || []).filter(({ startDate }: { startDate: string }) => new Date(startDate) < new Date()) as Seminar[];

    const sortedPreviousSeminars = previousSeminarDetails.sort((a: Seminar, b: Seminar) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const [upNext, ...upcomingSeminars] = sortedUpcomingSeminars;

    return (
        <>
            {isSuccess && <div className="relative"> {upNext ? <UpNextEvent seminar={upNext} /> : <NoUpcomingEvent />}</div>}
            {upcomingSeminars?.length > 0 && <UpcomingSeminars seminars={upcomingSeminars} />}
            {sortedPreviousSeminars?.length > 0 && <PastOrStartedSeminars seminars={sortedPreviousSeminars} />}
        </>
    );
};
